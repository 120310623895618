export const DEFAULT = {
  COLOR: {
    r: 120,
    g: 210,
    b: 30,
    a: 0.5,
  },
  RADIUS: 10,
  RADIUS_MIN: 2,
  RADIUS_MAX: 20,
};
