import {
  EventSearch,
  DateRange,
  CogniteInternalId,
  IdEither,
  Limit,
  SinglePatchString,
  SinglePatchDate,
  ObjectPatch,
  Metadata,
  RemoveField,
  SetField,
  InternalId,
  ExternalId,
  FilterQuery,
  NullableSinglePatchLong,
} from '@cognite/sdk';

export const ANNOTATION_METADATA_PREFIX = 'CDF_ANNOTATION_';
export const ANNOTATION_EVENT_TYPE = 'cognite_annotation';

export const CURRENT_VERSION = 5;

export type AnnotationStatus = 'verified' | 'deleted' | 'unhandled';

export type AnnotationResourceType =
  | 'asset'
  | 'file'
  | 'timeSeries'
  | 'threeD'
  | 'threeDRevision'
  | 'event'
  | 'sequence';

export type AnnotationBoundingBox = {
  xMax: number;
  xMin: number;
  yMax: number;
  yMin: number;
};

export interface CogniteAnnotation {
  id: number;
  externalId?: string;
  startTime?: Date;
  endTime?: Date;
  createdTime: Date;
  lastUpdatedTime: Date;
  type: string;
  description?: string;
  label: string;
  fileId?: number;
  fileExternalId?: string;
  status: AnnotationStatus;
  box: AnnotationBoundingBox;
  resourceId?: number;
  resourceExternalId?: string;
  resourceType?: AnnotationResourceType;
  checkedBy?: string;
  page?: number;
  version: number;
  source: string;
  metadata?: { [key: string]: string };
}

export interface PendingCogniteAnnotation
  extends Omit<
    Omit<Omit<CogniteAnnotation, 'id'>, 'createdTime'>,
    'lastUpdatedTime'
  > {}

export type SinglePatchStatus = SetField<AnnotationStatus>;

export type SinglePatchResourceType =
  | SetField<AnnotationResourceType>
  | RemoveField;

export type SinglePatchBoundingBox = SetField<AnnotationBoundingBox>;

export interface DetectionFilter {
  startTime?: DateRange;
  endTime?: DateRange;
  createdTime?: DateRange;
  lastUpdatedTime?: DateRange;
  metadata?: Metadata;
  /**
   * Asset IDs of related equipment that this event relates to.
   */
  assetIds?: CogniteInternalId[];
  /**
   * The IDs of the root assets that the related assets should be children of.
   */
  rootAssetIds?: IdEither[];
  /**
   * Filter by label
   */
  label?: string;
  /**
   * Filter detections with an externalId starting with this value
   */
  externalIdPrefix?: string;
  /**
   * Filter based on file id
   */
  fileExternalId?: string;
}

export interface DetectionFilterRequest extends FilterQuery {
  filter: DetectionFilter;
}

export interface CogniteAnnotationPatch {
  id: CogniteInternalId;
  annotation: CogniteAnnotation;
  update: {
    externalId?: SinglePatchString;
    startTime?: SinglePatchDate;
    endTime?: SinglePatchDate;
    type?: SinglePatchString;
    description?: SinglePatchString;
    label?: SinglePatchString;
    fileId?: NullableSinglePatchLong;
    fileExternalId?: SinglePatchString;
    status?: SinglePatchStatus;
    box?: SinglePatchBoundingBox;
    resourceId?: NullableSinglePatchLong;
    resourceExternalId?: SinglePatchString;
    resourceType?: SinglePatchResourceType;
    checkedBy?: SinglePatchString;
    page?: NullableSinglePatchLong;
    metadata?: ObjectPatch;
  };
}

export interface DetectionChangeById
  extends CogniteAnnotationPatch,
    InternalId {}

export interface DetectionChangeByExternalId
  extends CogniteAnnotationPatch,
    ExternalId {}

export type DetectionChange = DetectionChangeById | DetectionChangeByExternalId;

export interface DetectionSearchRequest extends Limit {
  filter?: DetectionFilter;
  search?: EventSearch;
}
