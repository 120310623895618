import { CogniteClient, InternalId } from '@cognite/sdk';
import {
  CogniteAnnotationSpec,
  CogniteAnnotation,
  CogniteAnnotationListFilter,
  CogniteAnnotationPaginationListResponse
} from './Annotation';

const baseUrl = (client: CogniteClient) =>
  `/api/playground/projects/${client.project}/context/annotations`;
const maxAnnotationsLimit: number = 1000;

export const createAnnotations = async (
  client: CogniteClient,
  pendingAnnotations: CogniteAnnotationSpec[]
): Promise<CogniteAnnotation[]> => {
  const response = await client.post<{ items: CogniteAnnotation[] }>(
    baseUrl(client),
    {
      data: {
        items: pendingAnnotations,
      },
    }
  );

  return response.data.items;
};

export const listAnnotationsCursor = async (
  client: CogniteClient,
  filter?: CogniteAnnotationListFilter, 
  limit?: number,
  cursor?: string
): Promise<CogniteAnnotationPaginationListResponse> => {
  if (limit !== null  && limit !== undefined && limit < 1) {
    console.error("Limit can not be smaller than 1");
  }

  const response = await client.post<{ items: CogniteAnnotation[], nextCursor?: string }>(
    `${baseUrl(client)}/list`,
    {
      data: {filter, limit:limit, cursor:cursor}
    }
  );
  return {
    items: response.data.items,
    ...(response.data.nextCursor ? { nextCursor: response.data.nextCursor } : {})
  };
};

export const listAnnotations = async (
  client: CogniteClient,
  filter?: CogniteAnnotationListFilter, 
  limit?: number,
): Promise<CogniteAnnotation[]> => {
  const limitVar = limit === -1 ? undefined : limit;
  let remaining: number|undefined = limitVar; 
  let result: CogniteAnnotation[] = [];
  let cursor: string|undefined;
  let currentLimit: number = maxAnnotationsLimit;

  while (remaining === undefined || remaining > 0) {
    if (remaining !== undefined) {
      currentLimit = Math.min(currentLimit, remaining);
    }
    const response  = await listAnnotationsCursor(client, filter, currentLimit, cursor);
    result.push.apply(result, response.items)
    if (response.nextCursor === undefined) {
      break
    }
    cursor = response.nextCursor;
    if (remaining !== undefined) {
      remaining -= response.items.length
    }
  }
  return result
};

export const retrieveAnnotations = async (
  client: CogniteClient,
  ids?: InternalId[]
): Promise<CogniteAnnotation[]> => {
  const response = await client.post<{ items: CogniteAnnotation[] }>(
    `${baseUrl(client)}/byids`,
    {
      data: { items: ids },
    }
  );

  return response.data.items;
};

export const getAnnotation = async (
  client: CogniteClient, 
  annotationId: InternalId
): Promise<CogniteAnnotation> => {
  const response = await client.get(`${baseUrl(client)}/${annotationId.id}`);
  return response.data
};

export const deleteAnnotations = async (
  client: CogniteClient,
  ids?: InternalId[]
): Promise<{}> => {
  await client.post<{ items: CogniteAnnotation[] }>(
    `${baseUrl(client)}/delete`,
    {
      data: { items: ids },
    }
  );

  return {};
};
